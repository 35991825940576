import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { projectsData } from "../AllData";
import ViewMoreBtn from "../common/viewMore_btn/ViewMoreBtn";
import ProjectCards from "./ProjectCards";
import "./Projects.css";

const Projects = () => {
  const ProjectsDatas = projectsData.slice(0, 3);
  const [allProject, setAllProject] = useState(ProjectsDatas);

  return (
    <>
      <div className="projectSection" id="projectSection">
        <div className="container">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h4 className="section-heading fw-light m-left">Projects</h4>
            <h3 className="section-sub-heading  pb-5 m-left">My Projects</h3>
          </div>
          <ProjectCards allProject={allProject} />

          <div className="btn-div">
            <NavLink to="/projects">
              <ViewMoreBtn visitBtnTitle="Visit Project Page" />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
