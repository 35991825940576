import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsStarFill } from "react-icons/bs";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";
import { testimonialData } from "../AllData";

// var swiper = new Swiper(".mySwiper", {
//   slidesPerView: 1,
//   grabCursor: true,
//   loop: true,
//   pagination: {
//     el: ".swiper-pagination",
//     clickable: true,
//   },
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
// });

const Testimonial = () => {
  return (
    <>
      <section className="testimonialSection" id="testimonialSection">
        <div className="container">
          <div
            className="headings"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h4 className="section-heading fw-light m-left">Testimonials</h4>
            <h3 className="section-sub-heading pb-5 m-left">
              Happiest Clients
            </h3>
          </div>

          <div className="slider-div">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {testimonialData.map((data) => {
                const { id, name, image, desc, job } = data;
                return (
                  <SwiperSlide key={id}>
                    <div className="slide swiper-slide slider-single-item">
                      <figure>
                        <img
                          src={image}
                          alt={id}
                          className="client-profile-image"
                          data-aos="zoom-in"
                          data-aos-easing="linear"
                          data-aos-duration="400"
                        />
                      </figure>

                      <div className="details-div">
                        <p
                          className="testimonial-description"
                          data-aos="fade-top"
                        >
                          {desc}
                        </p>

                        <div
                          data-aos="fade-top"
                          data-aos-easing="linear"
                          data-aos-duration="1200"
                        >
                          <div className="stars-div">
                            <BsStarFill className="start-icon" />
                            <BsStarFill className="start-icon" />
                            <BsStarFill className="start-icon" />
                            <BsStarFill className="start-icon" />
                            <BsStarFill className="start-icon" />
                            {/* <BsStarHalf className="start-icon" /> */}
                          </div>

                          <div className="details">
                            <span className="name">{name}</span>
                            <span className="job">{job}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
  // https://getbootstrap.com/docs/5.0/examples/carousel/#
};

export default Testimonial;
