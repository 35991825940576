import React, { useState } from "react";
import "./Interested.css";
import CopyToClipboard from "react-copy-to-clipboard";

const Interested = () => {
  const [textValue, setTextValue] = useState("asifromiz.bd@gmail.com");
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1600);
  };

  return (
    <>
      <section className="interestedSection">
        <div className="container text-center">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 text-div">
              <div
              // data-aos="fade-right"
              // data-aos-easing="linear"
              // data-aos-duration="600"
              >
                <h2 className="section-sub-heading">Are You Interested?</h2>
                <p className="int-desc">
                  I'll always open to discussing projects or work.
                </p>
              </div>
              <div className="int-btn-div">
                <CopyToClipboard text={textValue} onCopy={handleCopyText}>
                  <button
                    className="int-btn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Click to copy"
                    data-aos="fade-top"
                    data-aos-easing="linear"
                    data-aos-duration="600"
                  >
                    {isCopied ? "Email has been Copied!" : textValue}
                    <i className="fa-solid fa-copy copy-icon"></i>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 int-svg-div">
              <img
                src="./images/svg/interested.svg"
                alt="svg"
                className="int-svg"
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="600"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Interested;
