import React from "react";
import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/common/navbar/Navbar";
import Home from "./components/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Footer from "./components/common/footer/Footer";
import GotToTop from "./components/common/goToTop/GotToTop";
import Loader from "./components/common/loader/Loader";
import ScrollToTop from "./components/ScrollToTop";

function App() {

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <GotToTop />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
