import React from "react";
import "./FactsArea.css";

const FactsArea = () => {
  return (
    <>
      <section className="factSection">
        <div className="container text-center ">
          <div className="row mx-auto">
            <div className="col-10 col-md-6 col-lg-3 single-div">
              <div className="row ">
                <div className="icon-div col-5 text-light py-3">
                  <i
                    className="fa-sharp fa-solid fa-business-time facts-icon"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                    data-aos-duration="600"
                  ></i>
                </div>
                <div
                  className="text-div col-7 py-3"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="600"
                >
                  <h1 className="text-primary text-bold">500</h1>
                  <p className="text-div-heading text-div-p mb-1">
                    Hours Worked
                  </p>
                </div>
              </div>
            </div>

            <div className="col-10 col-md-6 col-lg-3 single-div">
              <div className="row">
                <div className="icon-div col-5 text-light py-3">
                  <i
                    className="fa-solid fa-layer-group facts-icon"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                    data-aos-duration="600"
                  ></i>
                </div>
                <div
                  className="text-div col-7 py-3"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="600"
                >
                  <h1 className="text-div-heading text-primary font-weight-bold ">
                    1200
                  </h1>
                  <p className="text-div-p mb-1">Project Finished</p>
                </div>
              </div>
            </div>

            <div className="col-10 col-md-6 col-lg-3 single-div">
              <div className="row">
                <div className="icon-div col-5 text-light border-right py-3">
                  <i
                    className="fa-regular fa-face-smile facts-icon"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                    data-aos-duration="600"
                  ></i>
                </div>
                <div
                  className="text-div col-7 py-3"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="600"
                >
                  <h1 className="text-div-heading text-primary font-weight-bold ">
                    2000
                  </h1>
                  <p className="text-div-p mb-1">Happy Clients</p>
                </div>
              </div>
            </div>

            <div className="col-10 col-md-6 col-lg-3 single-div">
              <div className="row">
                <div className="icon-div col-5 text-light border-right py-3">
                  <i
                    className="fa-solid fa-heart-pulse facts-icon"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                    data-aos-duration="600"
                  ></i>
                </div>
                <div
                  className="text-div col-7 py-3"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="600"
                >
                  <h1 className="text-div-heading text-primary font-weight-bold ">
                    2k
                  </h1>
                  <p className="text-div-p mb-1">Coffee Drinked</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FactsArea;
