import React, { useState } from "react";
import { projectsData } from "../components/AllData";
import FilterButtons from "../components/common/filterButtons/FilterButtons";
import PageHeading from "../components/common/pageHeading/PageHeading";
import ProjectCards from "../components/project/ProjectCards";
import "../components/project/Projects.css";
import TitleDynamic from "../components/TitleDynamic";

const categoryList = [
  "All",
  ...new Set(
    projectsData.map((data) => {
      return data.category;
    })
  ),
];

const Projects = () => {
  const [allProject, setAllProject] = useState(projectsData);
  // const [selectedCate, setSelectedCate] = useState(categoryList);

  const filterByCategory = (proCate) => {
    if (proCate === "All") {
      setAllProject(projectsData);
      return;
    }

    const updatedProjects = projectsData.filter((project) => {
      return project.category === proCate;
    });
    setAllProject(updatedProjects);
  };

  // title
  TitleDynamic("asifromiz.dev | projects");

  return (
    <>
      <PageHeading title="Project Page" />

      <div className="projectSection" id="projectSection">
        <div className="container">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h4 className="section-heading fw-light m-left">Projects</h4>
            <h3 className="section-sub-heading pb-5 m-left">My Projects</h3>
          </div>

          <FilterButtons
            filterByCategory={filterByCategory}
            categoryList={categoryList}
          />

          <div className="row gx-5">
            <ProjectCards allProject={allProject} />
            {/* {allProject.map((data) => {
              const { id, img, title, subTitle, desc } = data;
              return (
                <div
                  className="col col-11 col-lg-4 col-md-6 m-auto mt-0 singleProject"
                  key={id}
                >
                  <div className="project-card">
                    <div className="project-card-img-div">
                      <figure>
                        <img src={img} alt={id} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="project-card-detail-div">
                      <h4 className="project-title">{title}</h4>
                      <h3 className="project-subTitle f-20">{subTitle}</h3>
                      <p className="project-desc">{desc}</p>
                    </div>

                    <div className="singleProject-view-btn">
                      <button className="projectBtn-cta">
                        <NavLink to="/projects">
                          <span>View More</span>
                          <svg viewBox="0 0 13 10" height="10px" width="15px">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                          </svg>
                        </NavLink>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
