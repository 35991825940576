import React from "react";
import "./PageHeading.css";
import { useLocation } from "react-router-dom";

const PageHeading = ({ title }) => {
  const location = useLocation();
  return (
    <>
      <section className="pageHeading">
        <div className="container">
          <div
            className="left-div col-12 col-md-6 col-lg-4"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <h2 className="fw-bold mb-0 ">
              Home / {location.pathname.split("/")[1]}
            </h2>
            <h2 className="main-title text-denger mb-0">{title}</h2>
          </div>
          <div
            className="right-div col-12 col-md-6 col-lg-4 d-flex justify-content-end"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <img
              src="./images/svg/page_heading_vector.svg"
              alt="page-heading-svg"
              className="text-end"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default PageHeading;
