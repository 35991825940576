import React from "react";
import "./Hero.css";
import { NavLink } from "react-router-dom";

const Hero = () => {
  return (
    <>

      <section className="heroSection" id="heroSection">
        <div className="container">
          <div className="main-content">
            <div className="row ">
              <div
                className="col-12 col-lg-6 d-flex flex-row justify-content-center flex-column align-items-start left-content"
                // data-aos="fade-right"
                // data-aos-easing="linear"
                // data-aos-duration="600"
              >
                <h4>Hello! This is</h4>
                <h1>Asif Romiz</h1>
                <h3>
                  Front-End <span>Developer</span>
                </h3>
                <p className="text text-muted">
                  Self thought Graphics & Web Designer <br />
                  I'll make a qualify Design for you
                </p>
                <h5 className="text-success">Available for freelance Work</h5>
                <div className="hero-button-div mt-3 ">
                  <NavLink to="/contact">
                    <button className="primary-btn-style">
                      Hire Me
                    </button>
                  </NavLink>
                  <a  href="/images/Curriculum-Vitae_of_Asif_Romiz.pdf" download>
                    <button className="secondary-btn-style ml-3">Get Resume</button>
                  </a>
                </div>
              </div>

              <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center images-section order-last">
                <img
                  src="./images/bg-vestor.png"
                  alt="vector-bg"
                  className="bg-shape"
                />
                <img
                  src="./images/main_cover_img.png"
                  alt="main-cover"
                  className="main-hero-cover"
                />
              </div>

              <div className="icon-group"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
