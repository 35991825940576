import React from "react";
import PageHeading from "../components/common/pageHeading/PageHeading";
import ContactComponent from "../components/contact/Contact";
import TitleDynamic from "../components/TitleDynamic";

const Contact = () => {
  TitleDynamic("asifromiz.dev | contact");

  return (
    <>
      <PageHeading title="Contact Page" />
      <ContactComponent />
    </>
  );
};

export default Contact;
