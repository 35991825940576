import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <section className="footer" id="footer">
        <div className="site-section">
          <div className="container">
            <div className="cta d-block d-md-flex align-items-center px-5">
              <div
              // data-aos="fade-right"
              // data-aos-easing="linear"
              // data-aos-duration="400"
              >
                <h2 className="mb-0 text-light">Ready for a project?</h2>
                <h3 className="text-light">Let's get started!</h3>
              </div>
              <div
                className="ml-auto"
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="400"
              >
                <NavLink to="/contact">
                  <button className="cta-btn">Contact Me</button>
                </NavLink>
              </div>
            </div>

            <div className="row first-row pb-5">
              <div className="col-md-4 footer-logo-section">
                <img
                  src="./images/footer_logo.png"
                  alt="footer-logo"
                  className="footer-logo"
                />
              </div>

              <div className="col-md-4">
                <div className="footer-content text-center" data-aos="zoom-in">
                  <h3>Made with</h3>
                  <div className="made-language">
                    <p>
                      HTML, CSS, Bootstrap5,
                      <br />
                      React.js & Node.js
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="solcial-info">
                  <h3>Follow us</h3>
                  <ul className="list-unstyled social">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://github.com/asifromiz"
                      >
                        <i className="fa-brands fa-github social-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/asifromiz.uttam"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/asif_romiz/?hl=en"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/asif_romiz"
                      >
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/asif-romiz-106476251/"
                      >
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.youtube.com/asifromiz"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <hr className="hr-style" />

            <div className="footer-bottom">
              <div className="row">
                <div
                  className="col-12 col-md-6 text-start copyright"
                  // data-aos="flip-up"
                  // data-aos-easing="linear"
                  // data-aos-duration="400"
                >
                  <p>Design & Developed by me &copy; Asif Romiz {year}</p>
                </div>

                <div
                  className="col-12 col-md-6 text-end footer-menu"
                  // data-aos="flip-up"
                  // data-aos-easing="linear"
                  // data-aos-duration="400"
                >
                  <ul>
                    <li>
                      <NavLink to="/" style={{ textDecoration: "none" }}>
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/about" style={{ textDecoration: "none" }}>
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/gallery" style={{ textDecoration: "none" }}>
                        Gallery
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
