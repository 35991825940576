import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  // get theme in local storage
  const getThemeInStorage = () => {
    return JSON.parse(localStorage.getItem("theme"));
  };
  // get themeIcon in local storage
  const getThemeIconInStorage = () => {
    return JSON.parse(localStorage.getItem("themeIcon"));
  };

  const [menuIcon, setMenuIcon] = useState(false);
  const [theme, setTheme] = useState(getThemeInStorage() || "light-theme");
  const [themeIcon, setThemeIcon] = useState(getThemeIconInStorage());
  const [scrolled, setScrolled] = useState(false);

  const normalLink = "navbar-link";
  const activeLink = "navbar-link navbar-link-active";

  // navbar sticky
  const handleScrolling = () => {
    const offset = window.scrollY;
    if (offset > 166) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleTheme = () => {
    if (theme === "dark-theme") {
      setTheme("light-theme");
      setThemeIcon(true);
    } else {
      setTheme("dark-theme");
      setThemeIcon(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrolling);
    document.body.className = theme;
    localStorage.setItem("theme", JSON.stringify(theme));
    localStorage.setItem("themeIcon", JSON.stringify(themeIcon));
  }, [theme, themeIcon]);

  return (
    <>
      <section className={scrolled ? "navbar navbar-sticky" : "navbar"}>
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src="./images/logo.png" alt="logo" className="logo" />
          </NavLink>

          <div className={menuIcon ? "navbar-style active" : "navbar-style"}>
            <ul className="navbar-lists">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  onClick={() => setMenuIcon(!menuIcon)}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  onClick={() => setMenuIcon(!menuIcon)}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  onClick={() => setMenuIcon(!menuIcon)}
                >
                  Services
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/gallery"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  onClick={() => setMenuIcon(!menuIcon)}
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/projects"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  onClick={() => setMenuIcon(!menuIcon)}
                >
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  onClick={() => setMenuIcon(!menuIcon)}
                >
                  Contact
                </NavLink>
              </li>
              <li>
                <div className="theme-icon" onClick={() => toggleTheme()}>
                  {themeIcon ? (
                    <i className="fa-solid fa-moon"></i>
                  ) : (
                    <i className="fa-solid fa-sun"></i>
                  )}
                </div>
              </li>
            </ul>

            {/* two button for open and close of menu */}
            <div className="mobile-navbar-btn mobile-nav-icon">
              {menuIcon ? (
                  <i
                      className="fa-solid fa-xmark mobile-nav-icon close-outline"
                      onClick={() => setMenuIcon(!menuIcon)}
                  ></i>
              ) : (
                  <div
                      className={'menu-btn'}
                      onClick={() => setMenuIcon(!menuIcon)}
                  >
                    <div className={'icon-box'}>
                      <span className={'span-1'} />
                      <span className={'span-2'} />
                      <span className={'span-3'} />
                    </div>
                  </div>
              )}

              {/*<i*/}
              {/*  className="fa-solid fa-bars mobile-nav-icon"*/}
              {/*  name="menu-outline"*/}
              {/*  onClick={() => setMenuIcon(!menuIcon)}*/}
              {/*></i>*/}

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
