import React from "react";
import { NavLink } from "react-router-dom";
import "./Error.css";

const Error = () => {
  return (
    <div>
      <section className="errorSection" id="errorSection">
        <div className="container">
          <div className="row">
            <div className="main-div">
              <img
                src="./images/svg/error.svg"
                alt="svg-vector"
                className="errorSvg"
              />
              <h1 className="error-title">Page Not Found!</h1>

              <NavLink to="/" style={{ textDecoration: "none" }}>
                <button className="gotToHome-btn">
                  Go To Home Page
                  <span className="gotToHome-btn-span"></span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Error;
