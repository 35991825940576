import React from "react";
import ErrorPage from "../components/error/Error";
import TitleDynamic from "../components/TitleDynamic";

const Error = () => {
  TitleDynamic("asifromiz.dev | error");
  return (
    <>
      <ErrorPage />
    </>
  );
};

export default Error;