import React from "react";
import "./Skills.css";

const Skills = () => {
  return (
    <>
      <section className="skillsSection" id="skillsSection">
        <div className="container">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <h4 className="section-heading fw-light m-left">Skills</h4>
            <h3 className="section-sub-heading m-left">My Gathered skills</h3>
          </div>

          <div className="row">
            <div className="skills-lists ">
              <div className="row first-row">
                <div className="col col-11 col-lg-4 col-md-4"></div>
                <div
                  className="col col-11 col-lg-4 col-md-4"
                  // data-aos="fade-down"
                  // data-aos-easing="linear"
                  // data-aos-duration="1000"
                >
                  <p>HTML</p>
                  <p>CSS</p>
                  <p>SCSS</p>
                  <p>Tailwind CSS</p>
                  <p>Bootstrap</p>
                  <p>MS Word & Excel</p>
                  <p>MS PowerPoint</p>
                </div>
                <div className="col col-11 col-lg-4 col-md-4"></div>
              </div>

              <div className="row second-row">
                <div
                  className="col col-11 col-lg-4 col-md-4"
                  // data-aos="fade-down"
                  // data-aos-easing="linear"
                  // data-aos-duration="1000"
                >
                  <p>JavaScript</p>
                  <p>React.js</p>
                  <p>Redux.js</p>
                  <p>Node.js</p>
                  <p>Next.js</p>
                  <p>MongoDB</p>
                </div>
                <div className="col col-11 col-lg-4 col-md-4">
                  <div className="skills-icon-div">
                    <img
                      src="./images/svg/skill.svg "
                      alt="skills-icon"
                      className="skill-icon-svg"
                    />
                    <img
                      src="./images/svg/knowledge.svg "
                      alt="skills-icon"
                      className="knowledge-icon-svg"
                    />
                  </div>
                </div>
                <div
                  className="col col-11 col-lg-4 col-md-4"
                  // data-aos="fade-down"
                  // data-aos-easing="linear"
                  // data-aos-duration="1000"
                >
                  <p>Adobe Photoshop</p>
                  <p>Adobe Illustrator</p>
                  <p>Adobe Premeire Pro</p>
                  <p>Adobe XD</p>
                  <p>Figma UI/UX</p>
                </div>
              </div>

              <div className="row third-row">
                <div className="col col-11 col-lg-4 col-md-4"></div>
                <div
                  className="col col-11 col-lg-4 col-md-4"
                  // data-aos="fade-down"
                  // data-aos-easing="linear"
                  // data-aos-duration="1000"
                >
                  <p>Git & Github</p>
                  <p>WordPress</p>
                  <p>Sketch Art</p>
                  <p>Drawing</p>
                </div>
                <div className="col col-11 col-lg-4 col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Skills;
