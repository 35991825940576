import React from "react";
import PageHeading from "../components/common/pageHeading/PageHeading";
import Services from "../components/services/Services";
import ServicesEndData from "../components/services/ServicesEndData";
import TitleDynamic from "../components/TitleDynamic";

const ServicesPage = () => {
  TitleDynamic("asifromiz.dev | services");
  return (
    <>
      <PageHeading title="Services Page" />
      <Services />
      <ServicesEndData />
    </>
  );
};

export default ServicesPage;
