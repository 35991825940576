import React from "react";
import "./FilterButtons.css";
import {NavLink} from "react-router-dom";

const FilterButtons = ({ filterByCategory, categoryList }) => {
  return (
    <>
      <div className="filter-div">
        <h4 className="section-heading fw-bold" data-aos="zoom-in">
          Filter By Category
        </h4>
        <div
          className=" pb-3 text-center filter-links"
          data-aos="fade-top"
          data-aos-easing="linear"
          data-aos-duration="400"
        >
          <div className="filter-nav-div mt-3">
            {categoryList.map((category, index) => {
              return (
                <NavLink
                  className="filter-nav-btn"
                  // activeClassName="active"
                  onClick={() => filterByCategory(category)}
                  key={index}
                  to={''}
                >
                  {category}
                </NavLink>
              );
            })}
          </div>
        </div>

        <hr className="text-center filter-bottom-hr" />
      </div>
    </>
  );
};

export default FilterButtons;
