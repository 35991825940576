import React from "react";
import About from "../components/about/About";
import PageHeading from "../components/common/pageHeading/PageHeading";
import TitleDynamic from "../components/TitleDynamic";

const AboutPage = () => {
  TitleDynamic("asifromiz.dev | about");

  return (
    <>
      <PageHeading title="About Page" />
      <About />
    </>
  );
};

export default AboutPage;
