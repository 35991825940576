import React from "react";
import "./Services.css";

const ServicesCard = ({ data }) => {
  const { id, icon, bg_icon, title, desc } = data;
  return (
    <>
      <div className="col col-10 col-lg-3 col-md-6 m-auto mt-5" key={id}>
        <div className=" servicesCard-body">
          <i
            className={`bg-icon ${bg_icon}`}
            data-aos="zoom-in"
            data-aos-duration="1200"
          />
          <div className="card-info">
            <i
              className={`card-icon ${icon}`}
              // data-aos="fade-left"
              // data-aos-duration="800"
            />
            <h4
              className="card-title"
              data-aos="fade-top"
              data-aos-duration="1600"
            >
              {title}
            </h4>
            <p
              className="card-desc"
              data-aos="fade-top"
              data-aos-duration="1600"
            >
              {desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesCard;
