import React from "react";
// import { NavLink } from "react-router-dom";
import { servicesData } from "../AllData";
// import ViewMoreButton from "../common/viewMorebtn/ViewMoreButton";
import "./Services.css";
import ServicesCard from "./ServicesCard";

const Services = () => {
  // const [serviceData, setServiceData] = useState(servicesData);
  return (
    <div>
      <section className="servicesSection" id="servicesSection">
        <div className="container overflow-hidden service-container">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h4 className="section-heading text-center fw-light">Services</h4>
            <h3 className="section-sub-heading text-center fw-bold fs-2">
              My Offered Services
            </h3>
          </div>
          <div className="experience-card">
            <h1 className="text-center fw-bold">4+</h1>
            <h2 className="text-center">Years Working Experience</h2>
          </div>
          <div className="row pb-5">
            {servicesData.map((data) => {
              return <ServicesCard key={data.id} data={data} />;
            })}
          </div>

          {/* view More button */}
          {/* <NavLink to="/services">
            <ViewMoreButton />
          </NavLink> */}
        </div>
      </section>
    </div>
  );
};

export default Services;
