import React from "react";
import { servicesEndData } from "../AllData";
import "./ServicesEndData.css";

const ServicesEndData = () => {
  return (
    <div>
      <section className="servicesEndData" id="servicesEndData">
        <div className="container">
          <h4 className="section-heading text-center fw-light">Services</h4>
          <h3 className="section-sub-heading text-center pb-5">
            Services which i provide
          </h3>
          <div className="row">
            {servicesEndData.map((data) => {
              const { id, icon, title, desc } = data;
              return (
                <div
                  className="col-12 col-lg-4 col-md-6 services-data"
                  key={id}
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <i className={`servicesData-icon ${icon}`}></i>
                  <h4 className="servicesData-title">{title}</h4>
                  <div className="desc-div">
                    <p className="servicesData-desc">{desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesEndData;
