import React, { useState } from "react";
import "./Contact.css";
import { RiWhatsappFill } from "react-icons/ri";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [result, setResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_for_my_portfolio",
        "template_acpk6k9",
        e.target,
        "dXKpGiDHNmsGuFPD9"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setResult(true);
  };

  setTimeout(() => {
    setResult(false);
  }, 2000);

  return (
    <>
      <section className="contactSection" id="contactSection">
        <div className="container">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h4 className="section-heading fw-light m-left">contact</h4>
            <h3 className="section-sub-heading pb-5 m-left">Get In Touch</h3>
          </div>

          <div className="initialAddress mb-5">
            <div className="row">
              <div className="col col-12 col-md-4 col-lg-4 text-center phone">
                {/* <i className="fa-solid fa-user init-icon-style"></i> */}
                <RiWhatsappFill className="init-react-icon-style" />
                <h5 className="init-heading">WhatsApp</h5>
                <p className="init-desc">+1 (437) 967 3690</p>
              </div>
              <div className="col col-12 col-md-4 col-lg-4 text-center email">
                <i className="fa-solid fa-envelope init-icon-style"></i>
                <h5 className="init-heading">Email</h5>
                <p className="init-desc">asifromiz.bd@gmail.com</p>
              </div>
              <div className="col col-12 col-md-4 col-lg-4 text-center address">
                <i className="fa-solid fa-map-location-dot init-icon-style"></i>
                <h5 className="init-heading">Address</h5>
                <p className="init-desc">Toronto, ON, Canada</p>
              </div>
            </div>
          </div>

          <div className="contact-div">
            <div className="row gy-5">
              <div className="col col-12 col-md-6 col-lg-6 svg-div">
                <figure
                  data-aos="zoom-in"
                  data-aos-easing="linear"
                  data-aos-duration="600"
                >
                  <img src="./images/svg/contact.svg" alt="contact-svg" />
                </figure>
              </div>

              <div className="col col-12 col-md-6 col-lg-6 form-div">
                <form
                  // action="https://formspree.io/f/mdovodqr"
                  // method="POST"
                  onSubmit={sendEmail}
                >
                  <div className="form-heading text-center">
                    <h4
                      className="form-heading-text"
                      data-aos="fade-bottom"
                      data-aos-easing="linear"
                      data-aos-duration="600"
                    >
                      feel free to contact me
                    </h4>
                  </div>

                  <div className="mb-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Username"
                      // id="exampleInputEmail1"
                      // aria-describedby="emailHelp"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      // id="exampleInputEmail1"
                      // aria-describedby="emailHelp"
                      autoComplete="off"
                      required
                    />
                    <div id="emailHelp" className="form-text">
                      I'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="mb-3">
                    <textarea
                      type="text"
                      name="message"
                      className="form-control text-area"
                      placeholder="Write a message here.."
                      // id="exampleInputPassword1"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      required
                    />
                    <label className="form-check-label">Check me out</label>
                  </div>

                  {/* form submit button */}
                  <button className="submit-btn">
                    <div className="svg-wrapper-1">
                      <div className="svg-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <span>Send</span>
                  </button>

                  {result ? (
                    <p className="submit-alert text-center">
                      Successfully Sent Message!
                    </p>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
