export const servicesData = [
  {
    id: "1",
    bg_icon: "fa-solid fa-code",
    icon: "fa-solid fa-code",
    title: "Web Devolopment",
    desc: "HTML5 | CSS | JAVASCRIPT",
  },
  {
    id: "2",
    bg_icon: "fa-brands fa-react",
    icon: "fa-brands fa-react",
    title: "Web Design",
    desc: "BOOTSTRAP | REACT",
  },
  {
    id: "3",
    bg_icon: "fa-solid fa-bezier-curve",
    icon: "fa-solid fa-bezier-curve",
    title: "Graphics Design",
    desc: "PHOTOSHOP | ILLUSTRATOR",
  },
  {
    id: "4",
    bg_icon: "fa-solid fa-pen-to-square",
    icon: "fa-solid fa-pen-to-square",
    title: "Sketch Drawing",
    desc: "PAPER | PENCIL",
  },
];
export const servicesEndData = [
  {
    id: "1",
    icon: "fa-solid fa-code",
    title: "Web Devolopment",
    desc: "Building and maintenance of websites. it’s make a website look great, fast & perform well with a seamless user experience by using a variety of coding languages",
  },
  {
    id: "2",
    icon: "fa-brands fa-react",
    title: "Web Design",
    desc: "Update or create the websites & online applications designs using various programming languages and design tools. Such us, HTML, CSS, Bootstrap5, JavaScript and react.js",
  },
  {
    id: "3",
    icon: "fa-solid fa-bezier-curve",
    title: "Graphics Design",
    desc: "A Graphic Designer is an artist who creates visual text and imagery. Design creative content for online campaigns, ads, logos, banners and even videos.",
  },
  {
    id: "4",
    icon: "fa-solid fa-pen-to-square",
    title: "Sketch Drawing",
    desc: "As a sketch artist, I'll create likenesses of people, usually drawing by hand and using a variety of professional art supplies or drawing materials.",
  },
  {
    id: "5",
    icon: "fa-solid fa-camera",
    title: "Photography",
    desc: "A photographer works closely with clients to capture photos of people, places and things through the use of creativity and technical abilities in photography.",
  },
  {
    id: "6",
    icon: "fa-solid fa-pen-to-square",
    title: "Clipping Path",
    desc: "Clipping path is an image editing technique that allows you to remove the background from an image. I can create a clipping path using the pen tool in Photoshop.",
  },
];

export const projectsData = [
  {
    id: "1",
    img: "/images/responsive_portfolio.jpg",
    category: "web",
    title: "Portfolio Website",
    subTitle: "Professional Responsive Portfolio web",
    desc: "I have experience of 4 years in web development and design. I created many modern, responsive, attractive, and professional Portfolio website...",
  },
  {
    id: "2",
    img: "/images/sketch_drawing.jpg",
    category: "art",
    title: "Sketch Drawing",
    subTitle: "Realistic Portrait Sketch & Drawings",
    desc: "Realistic Drawing with Paper and Pencil. I made a lots of portrait Sketch. Each and every portrait is truly beautiful, splendid and features are super fine...",
  },

  {
    id: "3",
    img: "/images/uiux.jpg",
    category: "graphics",
    title: "UX/UI Design",
    subTitle: "Creative UI/UX design for Mobile App",
    desc: "I made high quality, material, flat, modern, and professional Mobile App UI UX. Every Time i've done my research before starting the project. I use Adobe XD and Figma for creating a UI UX design...",
  },
  {
    id: "4",
    img: "/images/graphics_banner.jpg",
    category: "graphics",
    title: "Graphics Design",
    subTitle: "Professional Graphics Design for whole World",
    desc: "I am a professional graphic designer. I have designed many types of logos for various companies. Moreover i have made banner, business card, vector and more. I'll make any kind of design you need..",
  },
  {
    id: "5",
    img: "/images/responsive_portfolio.jpg",
    category: "web",
    title: "Responsive Website",
    subTitle: "Responsive website for All device",
    desc: "I have completed so many modern, responsive, attractive, and professional web for many website like restaurant & cafe. I am always seeking and following the latest trends keeping my work up to date...",
  },
];

export const testimonialData = [
  {
    id: 1,
    image: "./images/review1.jpg",
    name: "Maria Roy",
    job: "Designer",
    desc: "Excellent, working with web designer express was great.  Thanks to their knowledge and determination our website looks great and functions really good.  I am recommend anyone that is looking for a custom website to give them a call and speak to Gus, he will guide you to the right direction.",
  },
  {
    id: 2,
    image: "./images/review2.jpg",
    name: "Jhon",
    job: "Marketing Manager",
    desc: "Fantastic creativity. I am Satisfied on your work. Is there anyone need a designer or creative design then no need to look anywhere else. If I have to give a score from 1 to 10, I give 100 Very talented and professional, very satisfied with the result, highly recommend",
  },
  {
    id: 3,
    image: "./images/review3.jpg",
    name: "Angelina Decosta",
    job: "Business",
    desc: "Web Designer Express did a great job of not only creating our website how we wanted it to be, but also gave us great insight on what they believed will help boost sales. Their proven experience and excellence is the reason why our company would recommend Web Designer Express to anyone who is looking to create a customized website.",
  },
  {
    id: 4,
    image: "./images/review4.jpg",
    name: "Sr. Soan",
    job: "Book Writer",
    desc: "I got a super professional sketch from the author, which I wanted for my book. It's so beautiful, much better than what I had in my mind, capturing all that I wanted.This artist was wonderful. He listened to everything I asked and went back and changed exactly what I wanted revised which were very cosmetic simple things. I am so pleased with his work, timeliness, and clear and kind communication. For my first time using this service, I am very happy. 10/10!!",
  },
];
export const galleryData = [
  {
    id: "w31",
    img: "/images/gallery_img/web/web31.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w17",
    img: "/images/gallery_img/web/web17.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w14",
    img: "/images/gallery_img/web/web14.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w46",
    img: "/images/gallery_img/web/web46.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w56",
    img: "/images/gallery_img/web/web56.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "1",
    img: "/images/gallery_img/art/g1.jpg",
    category: "art",
    title: "Anahita Portrait Sketch",
  },
  {
    id: "2",
    img: "/images/gallery_img/graphics/g2.jpg",
    category: "graphics",
    title: "Couple Manipolation",
  },
  {
    id: "3",
    img: "/images/gallery_img/graphics/g3.jpg",
    category: "graphics",
    title: "Miniature World",
  },
  {
    id: "4",
    img: "/images/gallery_img/graphics/g4.jpg",
    category: "graphics",
    title: "15 Dec Sylhetbd.com",
  },
  {
    id: "5",
    img: "/images/gallery_img/art/g5.jpg",
    category: "art",
    title: "BTS V",
  },
  {
    id: "6",
    img: "/images/gallery_img/graphics/g6.jpg",
    category: "graphics",
    title: "AR Drawing Logo",
  },
  {
    id: "7",
    img: "/images/gallery_img/art/g7.jpg",
    category: "art",
    title: "Flower Art",
  },
  {
    id: "8",
    img: "/images/gallery_img/art/g8.jpg",
    category: "art",
    title: "Indian Girl Portrait",
  },
  {
    id: "9",
    img: "/images/gallery_img/graphics/g9.jpg",
    category: "graphics",
    title: "SylMetro Post Banner",
  },
  {
    id: "10",
    img: "/images/gallery_img/graphics/g10.jpg",
    category: "graphics",
    title: "Tahirpur Helpline",
  },
  {
    id: "11",
    img: "/images/gallery_img/graphics/g11.jpg",
    category: "graphics",
    title: "Multikart Banner",
  },
  {
    id: "12",
    img: "/images/gallery_img/art/g12.jpg",
    category: "art",
    title: "Allahu",
  },
  {
    id: "13",
    img: "/images/gallery_img/graphics/g13.jpg",
    category: "graphics",
    title: "My Cover Banner",
  },
  {
    id: "14",
    img: "/images/gallery_img/graphics/g14.jpg",
    category: "graphics",
    title: "Glamcute logo",
  },
  {
    id: "w1",
    img: "/images/gallery_img/web/web1.jpg",
    category: "web",
    title: "Portfolio Hero Section",
  },
  {
    id: "15",
    img: "/images/gallery_img/art/g15.jpg",
    category: "art",
    title: "Baby Boy Portrait",
  },
  {
    id: "16",
    img: "/images/gallery_img/graphics/g16.jpg",
    category: "graphics",
    title: "Multikart Banner",
  },
  {
    id: "17",
    img: "/images/gallery_img/graphics/g17.jpg",
    category: "graphics",
    title: "Nogod Banner",
  },
  {
    id: "18",
    img: "/images/gallery_img/art/g18.jpg",
    category: "art",
    title: "Rose Drawing",
  },
  {
    id: "19",
    img: "/images/gallery_img/graphics/g19.jpg",
    category: "graphics",
    title: "Tahirpur Helpline Banner",
  },
  {
    id: "20",
    img: "/images/gallery_img/graphics/g20.jpg",
    category: "graphics",
    title: "Tahirpur Helpline Visiting Card",
  },
  {
    id: "21",
    img: "/images/gallery_img/graphics/g21.jpg",
    category: "graphics",
    title: "Travel House Banner",
  },
  {
    id: "22",
    img: "/images/gallery_img/graphics/g22.jpg",
    category: "graphics",
    title: "Travel House Logo",
  },
  {
    id: "23",
    img: "/images/gallery_img/graphics/g23.jpg",
    category: "graphics",
    title: "SylMetro Post Banner",
  },
  {
    id: "w2",
    img: "/images/gallery_img/web/web2.jpg",
    category: "web",
    title: "Portfolio Page",
  },
  {
    id: "24",
    img: "/images/gallery_img/graphics/g24.jpg",
    category: "graphics",
    title: "Sylhetbd Post Banner",
  },
  {
    id: "25",
    img: "/images/gallery_img/graphics/g25.jpg",
    category: "graphics",
    title: "Sylhetbd Banner",
  },
  {
    id: "26",
    img: "/images/gallery_img/art/g26.jpg",
    category: "art",
    title: "Sketch Album",
  },
  {
    id: "27",
    img: "/images/gallery_img/graphics/g27.jpg",
    category: "graphics",
    title: "AR Logo Mockup",
  },
  {
    id: "28",
    img: "/images/gallery_img/graphics/g28.jpg",
    category: "graphics",
    title: "Dihan Delux Logo",
  },
  {
    id: "w3",
    img: "/images/gallery_img/web/web3.jpg",
    category: "web",
    title: "Portfolio Page",
  },
  {
    id: "29",
    img: "/images/gallery_img/graphics/g29.jpg",
    category: "graphics",
    title: "AR Cover Banner",
  },
  {
    id: "30",
    img: "/images/gallery_img/graphics/g30.jpg",
    category: "graphics",
    title: "Facebook Marketing Banner",
  },
  {
    id: "31",
    img: "/images/gallery_img/art/g31.jpg",
    category: "art",
    title: "Cat Sketch",
  },
  {
    id: "32",
    img: "/images/gallery_img/art/g32.jpg",
    category: "art",
    title: "Leo Messi",
  },
  {
    id: "w4",
    img: "/images/gallery_img/web/web4.jpg",
    category: "web",
    title: "Portfolio About Page",
  },
  {
    id: "33",
    img: "/images/gallery_img/art/g33.jpg",
    category: "art",
    title: "Arif Romiz Portrait",
  },
  {
    id: "34",
    img: "/images/gallery_img/art/g34.jpg",
    category: "art",
    title: "Sheikh Mujibur Rahman",
  },
  {
    id: "35",
    img: "/images/gallery_img/graphics/g35.jpg",
    category: "graphics",
    title: "YouTube Marketing Banner",
  },
  {
    id: "36",
    img: "/images/gallery_img/art/g36.jpg",
    category: "art",
    title: "Muntahana Portrait Sketch",
  },
  {
    id: "w5",
    img: "/images/gallery_img/web/web5.jpg",
    category: "web",
    title: "Portfolio Testimonial Section",
  },
  {
    id: "37",
    img: "/images/gallery_img/graphics/g37.jpg",
    category: "graphics",
    title: "Multiple Enterprise Visiting Card",
  },
  {
    id: "38",
    img: "/images/gallery_img/art/g38.jpg",
    category: "art",
    title: "Sketch for Book Cover",
  },
  {
    id: "39",
    img: "/images/gallery_img/art/g39.jpg",
    category: "art",
    title: "Girl Portrait Sketch",
  },
  {
    id: "w6",
    img: "/images/gallery_img/web/web6.jpg",
    category: "web",
    title: "Portfolio Skills Section",
  },
  {
    id: "40",
    img: "/images/gallery_img/art/g40.jpg",
    category: "art",
    title: "Allahu",
  },
  {
    id: "41",
    img: "/images/gallery_img/graphics/g41.jpg",
    category: "graphics",
    title: "Beanibazar Sale & Purchase Banner",
  },
  {
    id: "42",
    img: "/images/gallery_img/art/g42.jpg",
    category: "art",
    title: "Annum Portrait Sketch",
  },
  {
    id: "w7",
    img: "/images/gallery_img/web/web7.jpg",
    category: "web",
    title: "Portfolio Contact Page",
  },
  {
    id: "43",
    img: "/images/gallery_img/graphics/g43.jpg",
    category: "graphics",
    title: "Aids Banner",
  },
  {
    id: "44",
    img: "/images/gallery_img/art/g44.jpg",
    category: "art",
    title: "Realistick Eye Sketch",
  },
  {
    id: "45",
    img: "/images/gallery_img/art/g45.jpg",
    category: "art",
    title: "Ramadan Mubarak Drawing",
  },
  {
    id: "46",
    img: "/images/gallery_img/art/g46.jpg",
    category: "art",
    title: "Mohammed Name Art",
  },
  {
    id: "w8",
    img: "/images/gallery_img/web/web8.jpg",
    category: "web",
    title: "Portfolio Page",
  },
  {
    id: "47",
    img: "/images/gallery_img/art/g47.jpg",
    category: "art",
    title: "Pendamic Sitiuation Drawing",
  },
  {
    id: "48",
    img: "/images/gallery_img/art/g48.jpg",
    category: "art",
    title: "Lin Ny Portrait Sketch",
  },
  {
    id: "49",
    img: "/images/gallery_img/graphics/g49.jpg",
    category: "graphics",
    title: "Tahirpur HelpLine Banner",
  },
  {
    id: "w9",
    img: "/images/gallery_img/web/web9.jpg",
    category: "web",
    title: "Portfolio Services Section",
  },
  {
    id: "50",
    img: "/images/gallery_img/art/g50.jpg",
    category: "art",
    title: "Dark Sketch",
  },
  {
    id: "51",
    img: "/images/gallery_img/art/g51.jpg",
    category: "art",
    title: "Girl Sketch",
  },
  {
    id: "52",
    img: "/images/gallery_img/art/g52.jpg",
    category: "art",
    title: "Crown Sketch",
  },
  {
    id: "w10",
    img: "/images/gallery_img/web/web10.jpg",
    category: "web",
    title: "Portfolio Services Section",
  },
  {
    id: "53",
    img: "/images/gallery_img/graphics/g53.jpg",
    category: "graphics",
    title: "Miladunnobi Banner",
  },
  {
    id: "w11",
    img: "/images/gallery_img/web/web11.jpg",
    category: "web",
    title: "Portfolio Pricing Section",
  },
  {
    id: "54",
    img: "/images/gallery_img/graphics/g54.jpg",
    category: "graphics",
    title: "Sylhetbd Banner",
  },
  {
    id: "w12",
    img: "/images/gallery_img/web/web12.jpg",
    category: "web",
    title: "Portfolio Facts Section",
  },
  {
    id: "55",
    img: "/images/gallery_img/graphics/g55.jpg",
    category: "graphics",
    title: "Beanibazar Helpline Post Banner",
  },
  {
    id: "w13",
    img: "/images/gallery_img/web/web13.jpg",
    category: "web",
    title: "Portfolio Sections",
  },
  {
    id: "56",
    img: "/images/gallery_img/graphics/g56.jpg",
    category: "graphics",
    title: "Hostnana Vector",
  },
  {
    id: "w14",
    img: "/images/gallery_img/web/web14.jpg",
    category: "web",
    title: "Portfolio Projects Section",
  },
  {
    id: "57",
    img: "/images/gallery_img/graphics/g57.jpg",
    category: "graphics",
    title: "Sylhetbd Banner",
  },
  {
    id: "w15",
    img: "/images/gallery_img/web/web15.jpg",
    category: "web",
    title: "Portfolio Footer Section",
  },
  {
    id: "w16",
    img: "/images/gallery_img/web/web16.jpg",
    category: "web",
    title: "Portfolio About Section",
  },
  {
    id: "w17",
    img: "/images/gallery_img/web/web17.jpg",
    category: "web",
    title: "Portfolio Heading",
  },
  {
    id: "w18",
    img: "/images/gallery_img/web/web18.png",
    category: "web",
    title: "Car Dealer Hero",
  },
  {
    id: "w19",
    img: "/images/gallery_img/web/web19.png",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w20",
    img: "/images/gallery_img/web/web20.jpg",
    category: "web",
    title: "Footer",
  },
  {
    id: "w20",
    img: "/images/gallery_img/web/web20.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w21",
    img: "/images/gallery_img/web/web21.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w22",
    img: "/images/gallery_img/web/web22.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w23",
    img: "/images/gallery_img/web/web23.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w24",
    img: "/images/gallery_img/web/web24.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w25",
    img: "/images/gallery_img/web/web25.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w26",
    img: "/images/gallery_img/web/web26.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w27",
    img: "/images/gallery_img/web/web27.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w28",
    img: "/images/gallery_img/web/web28.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w29",
    img: "/images/gallery_img/web/web29.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w30",
    img: "/images/gallery_img/web/web30.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w32",
    img: "/images/gallery_img/web/web32.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w33",
    img: "/images/gallery_img/web/web33.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w34",
    img: "/images/gallery_img/web/web34.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w35",
    img: "/images/gallery_img/web/web35.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w36",
    img: "/images/gallery_img/web/web36.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w37",
    img: "/images/gallery_img/web/web37.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w38",
    img: "/images/gallery_img/web/web38.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w39",
    img: "/images/gallery_img/web/web39.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w40",
    img: "/images/gallery_img/web/web40.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w41",
    img: "/images/gallery_img/web/web41.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w42",
    img: "/images/gallery_img/web/web42.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w43",
    img: "/images/gallery_img/web/web43.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w44",
    img: "/images/gallery_img/web/web44.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w45",
    img: "/images/gallery_img/web/web45.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w46",
    img: "/images/gallery_img/web/web46.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w47",
    img: "/images/gallery_img/web/web47.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w48",
    img: "/images/gallery_img/web/web48.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w49",
    img: "/images/gallery_img/web/web49.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w50",
    img: "/images/gallery_img/web/web50.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w51",
    img: "/images/gallery_img/web/web51.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w52",
    img: "/images/gallery_img/web/web52.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w53",
    img: "/images/gallery_img/web/web53.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w54",
    img: "/images/gallery_img/web/web54.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w55",
    img: "/images/gallery_img/web/web55.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w56",
    img: "/images/gallery_img/web/web56.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w57",
    img: "/images/gallery_img/web/web57.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w58",
    img: "/images/gallery_img/web/web58.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w59",
    img: "/images/gallery_img/web/web59.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w60",
    img: "/images/gallery_img/web/web60.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w61",
    img: "/images/gallery_img/web/web61.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w62",
    img: "/images/gallery_img/web/web62.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w63",
    img: "/images/gallery_img/web/web63.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w64",
    img: "/images/gallery_img/web/web64.jpg",
    category: "web",
    title: "Feature Product",
  },
  {
    id: "w65",
    img: "/images/gallery_img/web/web60.jpg",
    category: "web",
    title: "Feature Product",
  }
];
