import React from "react";
import "./About.css";

const About = () => {
  // scrollReveal.reveal(".section-heading", { delay: 600 });

  return (
    <>
      <section className="aboutSection" id="aboutSection">
        <div className="container about-container">
          <div className="row gx-5">
            {/* about left content */}
            <div className="col-12 col-lg-6 about-left-side d-flex justify-content-center align-items-center aboutSection-images as-images order-lg-first order-last">
              <img
                src="./images/about_img.JPG"
                alt="about-img"
                className="img-fluid about-main-img"
              />
              <img
                src="./images/name_tag.png"
                alt="name-tag"
                className="img-fluid about-img-img2"
                // data-aos="fade-right"
                // data-aos-duration="2000"
              />
            </div>

            {/* about right content */}

            <div className="col-12 col-lg-6 about-right-side d-flex justify-content-center flex-column align-items-start ">
              <div className="about-text">
                {/* <div
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="600"
                > */}
                <h4 className="section-heading text-end fw-light">About Me</h4>
                <h3 className="section-sub-heading text-end pb-5">
                  Personal Infromation
                </h3>
                {/* </div> */}

                {/* <div
                  className="details"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                > */}
                <h3 className="about-mini-title">Graphics & Web Designer</h3>
                <p className="pera1">
                  Hi, This is Asif Romiz from Sylhet, Bangladesh.
                </p>
                <p className="pera2">
                  I am a professional Graphics designer, Front-End Developer &
                  Sketch Artist. I am a senior designer in a Softwere Farm. I've
                  a gathered four years of experience in this field. My
                  Responsibility is to work on web, HTML, CSS, Bootstrap,
                  JavaScript, React.js and customise the Graphics Design.
                </p>
                <p className="pera3">
                  I've Completed my programming courses and gathered this
                  knowledge from the Udemy. Also i've a God gifted talent which
                  is Drawing/Sketch.
                </p>
                {/* </div> */}
                <br />

                <div className="cv-button" data-aos="fade-top">
                  <a href="/images/Curriculum_Vitae_of_Asif_Romiz.pdf" download>
                    <div className="btn-text">Download CV</div>
                    <span className="icon">
                      <i className="fa-solid fa-download"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
