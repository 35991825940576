import React from "react";
import "./ViewMoreBtn.css";

const ViewMoreBtn = ({ visitBtnTitle }) => {
  return (
    <>
      <div className="view-More-btn">
        <span className="top-key"></span>
        <span className="view-btn-text">{visitBtnTitle}</span>
        <span className="view-btn-key-1"></span>
        <span className="view-btn-key-2"></span>
      </div>
    </>
  );
};

export default ViewMoreBtn;
