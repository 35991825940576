import React from  'react';
import { Link } from 'react-router-dom';
import './RecentProjects.css';

const ReacentProjects = () => {

    const handleClick = () => {
        alert('Opps!!..server can not run.')
    }

    return (
        <>
            <section className='recentProject'>
                <div className='container'>
                    <h4 className="section-heading fw-light m-left">Completed</h4>
                    <h3 className="section-sub-heading  pb-5 m-left">Recent Projects</h3>

                    <div className='project-container first-project'>
                        <div className='row'
                             data-aos="fade-right"
                             data-aos-easing="linear"
                             data-aos-duration="600"
                        >
                            <div className='col-md-6 img-box'>
                                <div
                                    className='project-img my-5 d-flex flex-column align-items-center justify-content-center'>
                                    <img src='./images/online-world-bd.png' alt='online-world-bd'/>
                                </div>
                            </div>

                            <div className='col-md-6 content'>
                                <div className='project-content my-5'>
                                    <div className='d-flex flex-column align-items-start'>
                                        <h6 className='project-subTitle'>E-commerce Project</h6>
                                        <h3 className='project-title'>Online World BD</h3>

                                        <p className='mt-3 text-start'>This is a e-commerce project i working or develop
                                            an easy to use website interface using React.js. where peoples can search
                                            for products where a consumer is provided with a shopping cart
                                            application.</p>

                                        <a href={'https://onlineworldbd.com/'} className='primary-btn-style'
                                           target='_blank'>
                                            View Website
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='project-container last-project mt-5'>
                        <div className='row'
                             data-aos="fade-up"
                             data-aos-easing="linear"
                             data-aos-duration="600"
                        >
                            <div className='col-md-6 content'>
                                <div className='project-content my-5'>
                                    <div className='d-flex flex-column align-items-end'>
                                        <h6 className='project-subTitle'>Admin Panel App</h6>
                                        <h3 className='project-title'>Admin Panel</h3>

                                        <p className='mt-3 d-flex text-end'>Sales Dashboard is an admin panel that
                                            requires no introduction. Hence the name, it’s an ideal alternative that you
                                            can use for eCommerce sales, also you can effortlessly apply it to something
                                            else.</p>

                                        <a href={''} className='primary-btn-style' onClick={handleClick}>
                                            View Website
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 img-box'>
                                <div
                                    className='project-img my-5 d-flex flex-column align-items-center justify-content-center'>
                                    <img src='./images/bts-admin-panel.png' alt='admin-panel'/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='project-container first-project'>
                        <div className='row'
                             data-aos="fade-right"
                             data-aos-easing="linear"
                             data-aos-duration="600"
                        >
                            <div className='col-md-6 img-box'>
                                <div
                                    className='project-img my-5 d-flex flex-column align-items-center justify-content-center'>
                                    <img src='./images/ocean19.png' alt='ocean19'/>
                                </div>
                            </div>

                            <div className='col-md-6 content'>
                                <div className='project-content my-5'>
                                    <div className='d-flex flex-column align-items-start'>
                                        <h6 className='project-subTitle'>Apartment Project</h6>
                                        <h3 className='project-title'>Ocean19</h3>

                                        <p className='mt-3 text-start'>This is a Beachfront Vacation Rental project i have developed.
                                            An easy to use website interface using Wordpress Gutenberg. where peoples can Book the comfort beachside private unit.</p>

                                        <a href={'https://ocean19.ca/'} className='primary-btn-style'
                                           target='_blank'>
                                            View Website
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='project-container last-project mt-5'>
                        <div className='row'
                             data-aos="fade-up"
                             data-aos-easing="linear"
                             data-aos-duration="600"
                        >
                            <div className='col-md-6 content'>
                                <div className='project-content my-5'>
                                    <div className='d-flex flex-column align-items-end'>
                                        <h6 className='project-subTitle'>Immigration Info Portal</h6>
                                        <h3 className='project-title'>Unitia Immigration</h3>

                                        <p className='mt-3 d-flex text-end'>Sales Dashboard is an admin panel that
                                            requires no introduction. Hence the name, it’s an ideal alternative that you
                                            can use for eCommerce sales, also you can effortlessly apply it to something
                                            else.</p>

                                        <a href={'https://unitiaimmigration.com/'} target={'_blank'} className='primary-btn-style'>
                                            View Website
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 img-box'>
                                <div
                                    className='project-img my-5 d-flex flex-column align-items-center justify-content-center'>
                                    <img src='./images/unitia-immigration.png' alt='admin-panel'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReacentProjects;