import React from "react";
import Hero from "./hero/Hero";
import About from "./about/About";
import Services from "./services/Services";
import Gallery from "./gallery/Gallery";
import Projects from "./project/Projects";
import FactsArea from "./portfolioFacts/FactsArea";
import Skills from "./skills/Skills";
import Testimonial from "./testimonials/Testimonial";
import Interested from "./intarested/Interested";
import TitleDynamic from "./TitleDynamic";
import ReacentProjects from "./common/recentProject/RecentProjects";

const Home = () => {
  TitleDynamic("asifromiz.dev | home");

  return (
    <>
      <Hero />
      <About />
      <Services />
      <FactsArea />
      <Skills />
      <Gallery />
      <Interested />
      <Projects />
      <ReacentProjects />
      <Testimonial />
      {/* <Contact /> */}
    </>
  );
};

export default Home;
