import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { galleryData } from "../AllData";
import ViewMoreBtn from "../common/viewMore_btn/ViewMoreBtn";
import "./Gallery.css";
import GalleryImg from "./GalleryImg";

const Gallery = () => {
  const GalleryFeatureImg = galleryData.slice(0, 3);

  const [previewImg, setPreviewImg] = useState({ img: "", i: 0 });
  const [view, setView] = useState(false);

  const preViewImage = (img, i) => {
    setPreviewImg({ img, i });
    setView(!view);
  };

  return (
    <>
      <div className="gallerySection" id="gallerySection">
        <div className="container">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h4 className="section-heading fw-light m-left">Gallery</h4>
            <h3 className="section-sub-heading pb-5 m-left">
              My Image Gallery
            </h3>
          </div>

          {previewImg.img && (
            <div
              className={
                view ? "preView-img--div preview-open" : "preView-img--div"
              }
            >
              <img src={previewImg.img} alt="" className="preview-img--style" />
              <i
                className="fa-solid fa-xmark image-close-icon"
                onClick={() => setView(!view)}
              ></i>
            </div>
          )}

          <GalleryImg images={GalleryFeatureImg} preViewImage={preViewImage} />

          <div className="btn-div">
            <NavLink to="/gallery">
              <ViewMoreBtn visitBtnTitle="Visit Gallery Page" />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
