import React, { useState } from "react";
import { galleryData } from "../components/AllData";
import FilterButtons from "../components/common/filterButtons/FilterButtons";
import PageHeading from "../components/common/pageHeading/PageHeading";
import "../components/gallery/Gallery.css";
import GalleryImg from "../components/gallery/GalleryImg";
import TitleDynamic from "../components/TitleDynamic";

const categoryList = [
  "All",
  ...new Set(
    galleryData.map((data) => {
      return data.category;
    })
  ),
];

const Gallery = () => {
  const [allImages, setAllImages] = useState(galleryData);
  // const [selectedCate, setSelectedCate] = useState(categoryList);
  const [previewImg, setPreviewImg] = useState({ img: "", i: 0 });
  const [view, setView] = useState(false);

  const filterByCategory = (imgCate) => {
    if (imgCate === "All") {
      setAllImages(galleryData);
      return;
    }

    const updatedImages = galleryData.filter((image) => {
      return image.category === imgCate;
    });
    setAllImages(updatedImages);
  };

  const preViewImage = (img, i) => {
    setPreviewImg({ img, i });
    setView(!view);
  };

  //title
  TitleDynamic("asifromiz.dev | gallery");

  return (
    <>
      <PageHeading title="Gallery Page" />

      <div className="gallerySection" id="gallerySection">
        <div className="container">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <h4 className="section-heading fw-light m-left">Gallery</h4>
            <h3 className="section-sub-heading pb-5 m-left">
              My Image Gallery
            </h3>
          </div>

          {/* filter section */}
          <FilterButtons
            filterByCategory={filterByCategory}
            categoryList={categoryList}
          />

          {previewImg.img && (
            <div
              className={
                view ? "preView-img--div preview-open" : "preView-img--div"
              }
            >
              <img src={previewImg.img} alt="" className="preview-img--style" />
              <i
                className="fa-solid fa-xmark image-close-icon"
                onClick={() => setView(!view)}
              ></i>
            </div>
          )}

          <GalleryImg images={allImages} preViewImage={preViewImage} />
        </div>
      </div>
    </>
  );
};

export default Gallery;
