import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const GalleryImg = ({ images, preViewImage }) => {
  return (
    <>
      <div className="all-images">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="16px">
            {images.map((image, i) => {
              return (
                <img
                  src={image.img}
                  alt={i}
                  key={i}
                  className="imgStyle"
                  onClick={() => preViewImage(image.img, i)}
                />
              );
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  );
};

export default GalleryImg;
