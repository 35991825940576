import React from "react";
import { NavLink } from "react-router-dom";
import "./Projects.css";

const ProjectCards = ({ allProject }) => {
  return (
    <>
      {/* <div className="container">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="600"
        >
          <h4 className="section-heading fw-light m-left">Projects</h4>
          <h3 className="section-sub-heading  pb-5 m-left">My Projects</h3>
        </div> */}

      <div className="row">
        {allProject.map((data) => {
          const { id, img, title, subTitle, desc } = data;
          const slicedesc = desc.slice(0, 106);

          return (
            <div
              className="col col-12 col-lg-4 col-md-6 m-auto mt-0 singleProject"
              key={id}
              data-aos="fade-top"
              data-aos-easing="linear"
              data-aos-duration="600"
            >
              <div className="project-card">
                <div className="project-card-img-div">
                  <figure>
                    <img src={img} alt={id} className="img-fluid" />
                  </figure>

                  <NavLink to="/projects">
                    <div className="overlay">
                      <div className="overlay-text">View All</div>
                    </div>
                  </NavLink>
                </div>
                <div className="project-card-detail-div">
                  <h4 className="project-title">{title}</h4>
                  <h3 className="project-subTitle">{subTitle}</h3>
                  <p className="project-desc">{slicedesc}..</p>
                </div>

                <div className="singleProject-view-btn">
                  <button className="projectBtn-cta">
                    <NavLink to="/projects">
                      <span>View More</span>
                      <svg viewBox="0 0 13 10" height="10px" width="15px">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProjectCards;
